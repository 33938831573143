@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?i77w0q");
  src: url("fonts/icomoon.eot?i77w0q#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?i77w0q") format("truetype"),
    url("fonts/icomoon.woff?i77w0q") format("woff"),
    url("fonts/icomoon.svg?i77w0q#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-previous2:before {
  content: "\ea23";
}
.icon-next2:before {
  content: "\ea24";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-telegram:before {
  content: "\ea95";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-rss:before {
  content: "\ea9b";
}
.icon-youtube:before {
  content: "\ea9d";
}

body {
  font-family: "Noto Sans Display", "Noto Sans Malayalam";
  font-size: 16px;
  line-height: 28px;
}

.articleText {
  font-size: 20px;
  line-height: 30px;
}

li.nav-item:hover {
  background-color: #cdd0d0 !important;
}

.navbar-expand-xl .navbar-nav .nav-link {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

@media screen and (max-width: 1024px) {
  body {
    margin-bottom: 0;
  }

  .footerad {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f5f5f5;
  }
}

.navbar-nav .nav-link {
  font-size: 0.9rem;
}

.footericon {
  font-size: 1.5rem;
  color: white;
}

.boldtext {
  font-weight: bold;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.3rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}

hr {
  margin: 0;
}

.w-97 {
  width: 97%;
}

.w-90 {
  width: 95%;
}

a {
  color: #000;
  text-decoration: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /*  font-family: 'Ubuntu', 'Noto Sans Malayalam UI'; */

  font-family: "Noto Sans Display", "Noto Sans Malayalam";
  /*   font-style: normal;
   */
  font-weight: 600;
}

.headerbold {
  font-weight: 600;
}

.fs-small {
  font-size: smaller;
}

.small,
        small {
            font-size: .775em;
        }

        .text-yellow
        {
            color: #f6e58d !important;
        }
        
.navbar {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dropdown-menu {
  font-size: 14px;
}

.form-error {
  color: red;
}

.timeago {
  font-size: 13px;
}

.topcontainer {
  position: relative;
  text-align: center;
  color: white;
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  /* left: 0px; */
  padding-top: 100px;
  /*  background-color: #0000006b; */
  background: -webkit-linear-gradient(top, transparent 30%, #000 80%);
}

.bigtitle.categorytitle::before {
  height: 1.125rem;
  border-left-width: 0.225rem;
}

.bigtitle.categorytitle {
  font-weight: 600;
}

.redcategory::before {
  border-color: #e91802 !important;
}

.yellowcategory::before {
  border-color: #f1c40f !important;
}

.categorytitle::before {
  content: " ";
  display: inline-block;
  margin-right: 0.2rem;
  border-left: 2px solid #47a1d8;
  height: 0.8rem;
  padding: 0;
  width: 0;
}

.categorytitle {
  /* text-transform: uppercase; */
  font-weight: 500;
}

.videoContainer {
  position: relative;
}

.playBtnLg {
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  /*half of the width */
  margin-top: -25px;
  /*half of the height */
}

.playBtn {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  /*half of the width */
  margin-top: -15px;
  /*half of the height */
}
